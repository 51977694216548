.font(@font_name, @file_name, @weight, @style) {
  @font-face {
    font-family: @font_name;
    font-display: swap;
    src: url("/assets/layouts/fonts/@{file_name}.woff") format("woff"), url("/assets/layouts/fonts/@{file_name}.woff2") format("woff2");
    font-weight: @weight;
    font-style: @style;
  }

}

.font("Montserrat", "Montserrat-Regular", 400, normal);
.font("Montserrat", "Montserrat-SemiBold", 600, normal);
.font("Montserrat", "Montserrat-ExtraBold", 800, normal);