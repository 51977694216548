.DashLog { margin-left: 25px; }
.DashLog li { float: left; margin-right: 20px; }
.resolution-text-wrap h3 { font: 400 23px/120% Roboto, Helvetica, Arial, sans-serif; margin-bottom: 10px; }
#workwave-iframe iframe { height: 1240px !important; }
.widefat { border: 1px solid #cfcfcf; width: 100%; }
.widefat th, .widefat td { border: 1px solid #cfcfcf; padding: 10px; text-align: left; }
.widefat thead { background: #bdd3e7 none repeat scroll 0 0; }
.widefat th { color: #1d64c1; font-weight: bold; }
.widefat tbody { }
.widefat a { box-shadow: none; color: #1d64c1; }
.pagination_block { background: #efefef none repeat scroll 0 0; border: 1px solid #ddd; padding: 4px 6px; }
.doc-list-container { min-height: 400px; }
table.widefat tr:nth-child(even) { background: #eaf8f8; }
.widefat { margin-top: 12px; }
.padding_ele { padding: 0 20px; }
#iframe { width: 100%; height: 530px; border: 0; }
.map { height: auto; }
.page-bg.bg-1 img { display: none }
.fade.in { opacity: 1; }
#name_error { color: red; }
#email_error,.error_msg { color: red; }
.success_msg { color: green; }
#com_error { color: red; }
#term_condition_error { color: red; }
#info_show { margin-top: 10px; padding: 10px; display: none; border: 1px solid grey; }
#info_show .close { margin-bottom: 5px; }
.main_img { text-align: center; }
.img_title p, .img_title h5 { color: #fff !important; font-weight: 500; }
.main_img img { height: 200px; object-fit: cover; }
.vgap {
  margin-bottom: 20px;
}
.display_hide{
  display: none;
}

.my-donate form {
  width: 145px;
}

* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 30.33%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

img.my-img {
  padding: 12px;
}

.how-section1 h4{
  color: #ffa500;
  font-weight: bold;
  font-size: 30px;
}
.how-section1 .subheading{
  color: #3931af;
  font-size: 20px;
}
.how-section1 .row
{
  margin-top: 6%;
}
.how-img
{
  text-align: center;
}
.how-img img{
  width: 40%;
}

.col-md-6.my-right {
  float: left;
  margin-left: 30px;
}

hr {
  display: block;
  width: 100%;
  height: 2px;
  visibility: inherit;
  opacity: 1;
  clear: both;
  float: none;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 10px !important;}

@media only screen and (min-width: 768px) {
}
@media only screen and (max-device-width: 767px) {
  .youtube_icon { }
  .modal-dialog { margin: 0 !important; }
}
@media print {
  @page {margin: 0;}
  body { margin: 1.6cm; }
  .header, footer { display: none; }
}


@media screen and (max-width: 550px) {
  .brochure-item .brochure-item__text {
    padding: 20px;
  }
}
@media screen and (max-width: 480px) {
  .brochure-item.larger {
    width: 100%;
  }
}
.pagination { display: inline-block; }
.pagination a { color: red; float: left !important; padding: 8px 16px; text-decoration: none; }
.pagination li { float: left; }

.iner_soc_icon li {
  float: left;
}

.iner_soc_icon > ul > li:before{
  content: none !important;
}
/*Blo Page css end*/

.big-links .big-link_residential::after {
  background-image: url("@{img}image4.jpg");
}

.fa-phone::before {
  content: "\f879";
}


.title {
  position: relative;
  text-align: left;
}

.title > *::after {
  content: '';
  display: block;
  width: 68px;
  height: 5px;
  background: #002554;
  position: absolute;
  bottom: -2.5px;
  left: 10%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}