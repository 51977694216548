//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

// BIG HEADER
// ====================================
.header {
  @media (min-width: 960px) and (max-width: 2480px)  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: 100%;
    max-width: 2200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: @color_w;

    > .resolution-wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 0px;
    }

    .header__pre-header{
      z-index: 3;
      background-color: @color_1;
      color: @color_w;
      position: relative;
      width: 100%;
      display: flex;

      > .resolution-wrap {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        height: 57px;
      }

    }

    .header__nav{
      display: flex;

      a{
        display: flex;
        height: 30px;
        padding: 0 10px;
        color: @color_w;
        text-decoration: none;
        align-content: center;
        align-items: center;
        justify-content: center;
        font: @f_reg 14px/120% @font;
      }

      .header__nav__action-btn a{
        border: 1px solid @color_w;
      }
    }

    .header__soc-links{
      display: flex;
      position: relative;
      left: 120px;

      a{
        margin-right: 10px;
        text-decoration: none;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 24px;
        height: 24px;
        color: @color_w;
        font: @f_reg 14px/110% @font;
      }
    }

    .logo{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 91px;
      height: 120px;
      position: absolute;
      z-index: 4;
      left: @gap;
      top: 10px;

      &::before{
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: @color_w;
      }

      > a{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 0 2px rgba(@color_1, 1);
      }


      svg{
        width: 85% !important;
        height: auto !important;

      }

    }

    .language{
      display: inline-flex;
      margin-right: auto;
      margin-left: @gap*2;
      position: relative;

      a{
        display: inline-flex;
        text-transform: uppercase;
        color: @color_b3;
        padding: 0 10px;
        text-decoration: none;
        position: relative;

        &:not(:last-child){
          &::after{
            position: absolute;
            top:0;
            right: 0;
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background: @color_b3;
          }
        }

        &.active{
          color: @color_b1;
        }
      }
    }

    .menu-burger {
      display: none;
    }

    .header__header-menu{
      > .resolution-wrap{
        position: relative;
        align-content: center;
        align-items: center;
        justify-content: right;
        height: 82px;
      }
    }

    .menu {
      display: flex;
      position: absolute;
      right: @gap;
      top: 50%;
      transform: translateY(-50%);

      &__wrap{
        display: flex;
        align-items: center;
        align-content: center;
      }

      &__item {
        margin-left: @gap;
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;

        &.active{
          > a::before, > .menu__sub-h::before{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -16px;
            width: 100%;
            height: 4px;
            background-color: @color_1;
          }
        }

        > a, > .menu__sub-h{
          display: flex;
          height: 36px;
          align-content: center;
          align-items: center;
          white-space: nowrap;
          text-decoration: none;
          font-size: 17px;
          color: @color_b1;
          transition: color .2s linear;

          i{
            &::before{
              color: @color_b1;
            }
            margin-right: 5px;
          }

          &:hover{
            color: @color_1;
            cursor: pointer;
            user-select: auto;
          }
        }

        > .menu__sub-h {
          &::after{
            content: @fa-var-angle-down;
            .fas;
            color: @color_b3;
            margin-left: 5px;
            transition: all .2s ease-out;
          }
        }

        .menu__sub{
          display: flex;
          flex-direction: column;
          align-items: stretch;
          align-content: stretch;
          top: 48px;
          left: -15px;
          position: absolute;
          opacity: 0;
          visibility: hidden;
          transition: all .2s ease-out;
          background: @color_w;
          transform-origin: 0 0 !important;
          transform: translateY(-10px) scaleY(0);
          min-width: 180px;
          box-shadow: @shadow;
          padding-bottom: @gap;
          z-index: 3;

          &::before{
            content:'';
            display: block;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg);
            background: @color_w;
            position: absolute;
            z-index: -1;
            left: 10px;
            top: -4px;
          }

          > a{
            display: flex;
            padding: 13px 15px;
            text-decoration: none;
            color: @color_b1;
            font-size: 15px;
            line-height: 120%;
            position: relative;

            &:hover{
              color: @color_1;
            }

            &.active::before{
              content:'';
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: @color_1;
              top: 50%;
              left: -7px;
              transform: translateY(-50%);
              border-radius: 100%;
              box-shadow:0 5px 20px 0 rgba(@color_1, .7);
            }
          }
        }

        &:hover {
          > .menu__sub-h {
            color: @color_b1 + 10%;

            &::after{
              transform: scaleY(-1);
              color: @color_1;
            }
          }
          .menu__sub{
            opacity: 1;
            visibility: visible;
            transform: translateY(0) scaleY(1);
          }
        }

        // login btn
        &.menu__action-btn a{
          background-color: @color_1;
          color: @color_w;
          border-radius: 36px;
          padding: 0 20px;
          transition: all .2s linear;

          i{
            &::before{
              color: @color_w;
            }
          }

          &:hover{
            background: @color_1 + 30%;
            box-shadow: 0 3px 13px 0 rgba(@color_1, .4);
          }
        }
      }

      &__link-main-page{
        display: none;
      }
    }

    &.header__small:not(.mobile-header) {
      box-shadow: @shadow;

      .header__pre-header{
        > .resolution-wrap {
          height: 36px;
        }
      }

      .header__nav{
        a{
          height: 28px;
          font: @f_reg 12px/120% @font;
        }
      }

      .header__soc-links{
        left: 80px;
      }


      .logo{
        top: 8px;
        width: 60px;
        height: 70px;

        svg{

        }
      }

      .language{
        display: inline-flex;
        margin-right: auto;
        margin-left: @gap*2;
        position: relative;

        a{
          display: inline-flex;
          text-transform: uppercase;
          color: @color_b3;
          padding: 0 10px;
          text-decoration: none;
          position: relative;

          &:not(:last-child){
            &::after{
              position: absolute;
              top:0;
              right: 0;
              content: '';
              display: block;
              width: 1px;
              height: 100%;
              background: @color_b3;
            }
          }

          &.active{
            color: @color_b1;
          }
        }
      }

      .menu-burger {
        display: none;
      }

      .header__header-menu{
        > .resolution-wrap{
          height:51px;
        }
      }

      .menu {
        &__item {
          padding-top: 4px;
          padding-bottom: 4px;

          > a, > .menu__sub-h{
            font-size: 15px;
          }

          > .menu__sub-h {
            &::after{
              content: @fa-var-angle-down;
              .fas;
              color: @color_b3;
              margin-left: 5px;
              transition: all .2s ease-out;
            }
          }

          .menu__sub{
            > a{
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 280px) and (max-width: 959.98px){
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 2200px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: @color_w;
    z-index: 90;
    .box-shadow(@shadow);

    > .resolution-wrap {
      position: relative;
      display: flex;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 60px;
    }

    .header__pre-header {
      display: none;
    }

    .header__soc-links {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 50%;
      margin-top: @gap;
      transform: translateX(-50%);

      a {
        margin-right: 10px;
        text-decoration: none;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 36px;
        height: 36px;
        color: @color_w;
        font: @f_reg 20px/110% @font;
      }
    }


    .logo {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 65px;
      height: 75px;
      position: absolute;
      z-index: 4;
      left: 50%;
      top: 4px;
      transform: translateX(-50%);
      border-width: 2px;
      border-style: solid;
      border-color: @color_1;
      background: @color_w;
      box-shadow: 7px 10px 40px -2px rgba(0, 0, 0, 0.1);
      transition: all .3s linear;

      @media only screen and (max-width: 560px), only screen and (max-device-width: 560px) {
        width: 60px;
        height: 75px;
      }

      > a {
        position: relative;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }


      svg {
        width: 90% !important;
        height: auto !important;
      }

    }

    .menu-burger {
      display: flex;
      width: 46px;
      height: 46px;
      position: absolute;
      left: 10px;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }

      &::before,
      i,
      &::after {
        content: '';
        display: block;
        width: 60%;
        height: 2px;
        background-color: @color_1;
        position: absolute;
        transform-origin: 0 50%;
        left: 50%;
        transform: translateX(-50%);
        transition: all .2s ease-out;
      }

      &::before {
        top: 30%;
      }

      i {
        top: 50%;
      }

      &::after {
        top: 70%;
      }
    }

    .menu-search {
      display: flex;
      width: 46px;
      height: 46px;
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%);

      a {
        display: flex;
        width: 46px;
        height: 46px;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        text-decoration: none;

        &:after {
          content: '\f002';
          .far;
        }
      }

    }

    .header__header-menu {
      width: 100%;
      height: 0;
      top: 60px;
      position: absolute;

      > .resolution-wrap {
        position: relative;
        align-content: center;
        align-items: center;
        justify-content: right;
        height: 0;
      }
    }

    .menu {
      position: absolute;
      display: inline-flex;
      flex-direction: column;
      top: 0;
      //background: @color_b1;
      padding-top: 0;
      height: 100vh;
      left: 0;
      z-index: 2;
      flex: 1 1 auto;
      max-width: 288px;
      transition: all .3s ease-out;
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;

      &::before {
        content: '';
        pointer-events: none;
        width: calc(100% - 10px);
        height: 60px;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba(@color_b1, 0), rgba(@color_b1, 1));
      }

      .menu__wrap {
        background: @color_b1;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-width: 288px;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        z-index: 2;
        box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.3);

        .header__nav {
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            position: relative;
            flex-direction: column;

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background: @color_b1 + 10%;
              z-index: 1;
            }


            > a {
              display: flex;
              align-items: center;
              align-content: center;
              position: relative;
              z-index: 2;
              text-align: left;
              padding-left: 20px;
              padding-right: 20px;
              text-decoration: none;
              min-height: 50px;
              color: @color_w;

              &.active {
                &::after {
                  content: '';
                  display: block;
                  pointer-events: none;
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 3px;
                  height: 100%;
                  background-color: @color_1;
                }
              }
            }
          }
        }

        .menu__item {
          display: flex;
          position: relative;
          flex-direction: column;

          &.active {
            > a::before,
            > .menu__sub-h::before {
              content: '';
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              width: 3px;
              height: 100%;
              background-color: @color_1;
            }
          }

          &:not(:last-child) {
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background: @color_b1 + 10%;
              z-index: 1;
            }
          }

          > a, .menu__sub-h {
            display: flex;
            align-items: center;
            align-content: center;
            position: relative;
            z-index: 2;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            text-decoration: none;
            min-height: 50px;
            color: @color_w;

            i {
              display: flex;
              width: 30px;
              align-content: center;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              color: @color_w;
            }

            &.active {
              &::after {
                content: '';
                display: block;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                width: 3px;
                height: 100%;
                background-color: @color_1;
              }
            }
          }

          .menu__sub {
            display: flex;
            position: relative;
            flex-direction: column;
            background: @color_b1 + 5%;

            > a {
              display: flex;
              text-decoration: none;
              color: @color_w;
              font-size: 13px;
              text-align: left;
              padding: 13px 13px 13px 22px;
              position: relative;

              &.active::before {
                content: '';
                position: absolute;
                width: 3px;
                height: 100%;
                background-color: @color_1;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                pointer-events: none;
              }

              &:not(:last-of-type) {
                border-bottom: 1px solid rgba(@color_w, .05);
              }
            }
          }
        }

        &::after {
          content: '';
          display: block;
          width: 280px;
          height: 180px !important;
          min-height: 100px;
        }
      }
    }

    .language {
      display: inline-flex;
      margin-left: auto;
      position: relative;
      height: 20px;
      margin-right: -8px;

      a {
        display: inline-flex;
        text-transform: uppercase;
        color: @color_b3;
        padding: 0 8px;
        text-decoration: none;
        position: relative;

        &:not(:last-child) {
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background: @color_b3;
          }
        }

        &.active {
          color: @color_b1;
        }
      }
    }

    &.menu-active {
      .resolution-wrap {
        .menu-burger {
          &::before {
            top: 50%;
            left: 20%;
            transform: rotate(-40deg);
            width: 20px !important;
          }

          i {
            left: 70%;
            opacity: 0;
          }

          &::after {
            top: 50%;
            left: 20%;
            transform: rotate(40deg);
            width: 20px !important;
          }
        }

        .menu {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }

  }
}


.main_page .logo a{
  pointer-events: none !important;
}

.dynamic-padding{
  @media (min-width: 960px) and (max-width: 2480px){
    padding-top: 139px;
  }
  @media (min-width: 280px) and (max-width: 959.98px){
    padding-top: 60px;
  }
}

body:not(.local):not(.frd):not(.frc):not(.saltpoint):not(.programs){

}


// colors
body.local{
  .header {
    @media (min-width: 960px) and (max-width: 2480px)  {
      .header__pre-header{
        background-color: @color_local;
      }

      .logo{
        > a{
          box-shadow: inset 0 0 0 2px rgba(@color_local, 1);
        }

        svg{
          fill: @color_local !important;
        }
      }

      .menu {
        &__item {
          &.active{
            > a::before, > .menu__sub-h::before{
              background-color: @color_local;
            }
          }

          > a, > .menu__sub-h{
            &:hover{
              color: @color_local;
            }
          }

          .menu__sub{
            > a{
              &:hover{
                color: @color_local;
              }

              &.active::before{
                background-color: @color_local;
                box-shadow:0 5px 20px 0 rgba(@color_local, .7);
              }
            }
          }

          &:hover {
            > .menu__sub-h {
              &::after{
                transform: scaleY(-1);
                color: @color_local;
              }
            }
          }

          // login btn
          &.menu__action-btn a{
            background-color: @color_local;

            &:hover{
              background: @color_local + 30%;
              box-shadow: 0 3px 13px 0 rgba(@color_local, .4);
            }
          }
        }
      }
    }

    @media (min-width: 280px) and (max-width: 959.98px){
      .logo {
        border-color: @color_local;
      }

      .menu-burger {
        &::before,
        i,
        &::after {
          background-color: @color_local;
        }
      }

      .menu {
        .menu__wrap {
          .header__nav {
            div {
              > a {
                &.active {
                  &::after {
                    background-color: @color_local;
                  }
                }
              }
            }
          }

          .menu__item {
            &.active {
              > a::before,
              > .menu__sub-h::before {
                background-color: @color_local;
              }
            }

            > a, .menu__sub-h {
              &.active {
                &::after {
                  background-color: @color_local;
                }
              }
            }

            .menu__sub {
              > a {
                &.active::before {
                  background-color: @color_local;
                }
              }
            }
          }
        }
      }
    }
  }
}
body.frd{
  .header {
    @media (min-width: 960px) and (max-width: 2480px)  {
      .header__pre-header{
        background-color: @color_frd;
      }

      .logo{
        > a{
          box-shadow: inset 0 0 0 2px rgba(@color_frd, 1);
        }
      }

      .menu {
        &__item {
          &.active{
            > a::before, > .menu__sub-h::before{
              background-color: @color_frd;
            }
          }

          > a, > .menu__sub-h{
            &:hover{
              color: @color_frd;
            }
          }

          .menu__sub{
            > a{
              &:hover{
                color: @color_frd;
              }

              &.active::before{
                background-color: @color_frd;
                box-shadow:0 5px 20px 0 rgba(@color_frd, .7);
              }
            }
          }

          &:hover {
            > .menu__sub-h {
              &::after{
                transform: scaleY(-1);
                color: @color_frd;
              }
            }
          }

          // login btn
          &.menu__action-btn a{
            background-color: @color_frd;

            &:hover{
              background: @color_frd + 30%;
              box-shadow: 0 3px 13px 0 rgba(@color_frd, .4);
            }
          }
        }
      }
    }

    @media (min-width: 280px) and (max-width: 959.98px){
      .logo {
        border-color: @color_frd;
      }

      .menu-burger {
        &::before,
        i,
        &::after {
          background-color: @color_frd;
        }
      }

      .menu {
        .menu__wrap {
          .header__nav {
            div {
              > a {
                &.active {
                  &::after {
                    background-color: @color_frd;
                  }
                }
              }
            }
          }

          .menu__item {
            &.active {
              > a::before,
              > .menu__sub-h::before {
                background-color: @color_frd;
              }
            }

            > a, .menu__sub-h {
              &.active {
                &::after {
                  background-color: @color_frd;
                }
              }
            }

            .menu__sub {
              > a {
                &.active::before {
                  background-color: @color_frd;
                }
              }
            }
          }
        }
      }
    }
  }
}
body.frc{
  .header {
    @media (min-width: 960px) and (max-width: 2480px)  {
      .header__pre-header{
        background-color: @color_frc;
      }

      .logo{
        > a{
          box-shadow: inset 0 0 0 2px rgba(@color_frc, 1);
        }
      }

      .menu {
        &__item {
          &.active{
            > a::before, > .menu__sub-h::before{
              background-color: @color_frc;
            }
          }

          > a, > .menu__sub-h{
            &:hover{
              color: @color_frc;
            }
          }

          .menu__sub{
            > a{
              &:hover{
                color: @color_frc;
              }

              &.active::before{
                background-color: @color_frc;
                box-shadow:0 5px 20px 0 rgba(@color_frc, .7);
              }
            }
          }

          &:hover {
            > .menu__sub-h {
              &::after{
                transform: scaleY(-1);
                color: @color_frc;
              }
            }
          }

          // login btn
          &.menu__action-btn a{
            background-color: @color_frc;

            &:hover{
              background: @color_frc + 30%;
              box-shadow: 0 3px 13px 0 rgba(@color_frc, .4);
            }
          }
        }
      }
    }

    @media (min-width: 280px) and (max-width: 959.98px){
      .logo {
        border-color: @color_frc;
      }

      .menu-burger {
        &::before,
        i,
        &::after {
          background-color: @color_frc;
        }
      }

      .menu {
        .menu__wrap {
          .header__nav {
            div {
              > a {
                &.active {
                  &::after {
                    background-color: @color_frc;
                  }
                }
              }
            }
          }

          .menu__item {
            &.active {
              > a::before,
              > .menu__sub-h::before {
                background-color: @color_frc;
              }
            }

            > a, .menu__sub-h {
              &.active {
                &::after {
                  background-color: @color_frc;
                }
              }
            }

            .menu__sub {
              > a {
                &.active::before {
                  background-color: @color_frc;
                }
              }
            }
          }
        }
      }
    }
  }
}
body.saltpoint{
  .header {
    @media (min-width: 960px) and (max-width: 2480px)  {
      .header__pre-header{
        background-color: @color_saltpoint;
      }

      .logo{
        > a{
          box-shadow: inset 0 0 0 2px rgba(@color_saltpoint, 1);
        }
      }

      .menu {
        &__item {
          &.active{
            > a::before, > .menu__sub-h::before{
              background-color: @color_saltpoint;
            }
          }

          > a, > .menu__sub-h{
            &:hover{
              color: @color_saltpoint;
            }
          }

          .menu__sub{
            > a{
              &:hover{
                color: @color_saltpoint;
              }

              &.active::before{
                background-color: @color_saltpoint;
                box-shadow:0 5px 20px 0 rgba(@color_saltpoint, .7);
              }
            }
          }

          &:hover {
            > .menu__sub-h {
              &::after{
                transform: scaleY(-1);
                color: @color_saltpoint;
              }
            }
          }

          // login btn
          &.menu__action-btn a{
            background-color: @color_saltpoint;

            &:hover{
              background: @color_saltpoint + 30%;
              box-shadow: 0 3px 13px 0 rgba(@color_saltpoint, .4);
            }
          }
        }
      }
    }

    @media (min-width: 280px) and (max-width: 959.98px){
      .logo {
        border-color: @color_saltpoint;
      }

      .menu-burger {
        &::before,
        i,
        &::after {
          background-color: @color_saltpoint;
        }
      }

      .menu {
        .menu__wrap {
          .header__nav {
            div {
              > a {
                &.active {
                  &::after {
                    background-color: @color_saltpoint;
                  }
                }
              }
            }
          }

          .menu__item {
            &.active {
              > a::before,
              > .menu__sub-h::before {
                background-color: @color_saltpoint;
              }
            }

            > a, .menu__sub-h {
              &.active {
                &::after {
                  background-color: @color_saltpoint;
                }
              }
            }

            .menu__sub {
              > a {
                &.active::before {
                  background-color: @color_saltpoint;
                }
              }
            }
          }
        }
      }
    }
  }
}
body.programs{
  .header {
    @media (min-width: 960px) and (max-width: 2480px)  {
      .header__pre-header{
        background-color: @color_programs;
      }

      .logo{
        > a{
          box-shadow: inset 0 0 0 2px rgba(@color_programs, 1);
        }
      }

      .menu {
        &__item {
          &.active{
            > a::before, > .menu__sub-h::before{
              background-color: @color_programs;
            }
          }

          > a, > .menu__sub-h{
            &:hover{
              color: @color_programs;
            }
          }

          .menu__sub{
            > a{
              &:hover{
                color: @color_programs;
              }

              &.active::before{
                background-color: @color_programs;
                box-shadow:0 5px 20px 0 rgba(@color_programs, .7);
              }
            }
          }

          &:hover {
            > .menu__sub-h {
              &::after{
                transform: scaleY(-1);
                color: @color_programs;
              }
            }
          }

          // login btn
          &.menu__action-btn a{
            background-color: @color_programs;

            &:hover{
              background: @color_programs + 30%;
              box-shadow: 0 3px 13px 0 rgba(@color_programs, .4);
            }
          }
        }
      }
    }

    @media (min-width: 280px) and (max-width: 959.98px){
      .logo {
        border-color: @color_programs;
      }

      .menu-burger {
        &::before,
        i,
        &::after {
          background-color: @color_programs;
        }
      }

      .menu {
        .menu__wrap {
          .header__nav {
            div {
              > a {
                &.active {
                  &::after {
                    background-color: @color_programs;
                  }
                }
              }
            }
          }

          .menu__item {
            &.active {
              > a::before,
              > .menu__sub-h::before {
                background-color: @color_programs;
              }
            }

            > a, .menu__sub-h {
              &.active {
                &::after {
                  background-color: @color_programs;
                }
              }
            }

            .menu__sub {
              > a {
                &.active::before {
                  background-color: @color_programs;
                }
              }
            }
          }
        }
      }
    }
  }
}






