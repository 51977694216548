// SELECT BOX
.select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: @elements_h;
  max-height: @elements_h;
  cursor: pointer;

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_w;

      -webkit-box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b5);
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
        cursor: pointer;
      }
    }
  }

  * {
    .anim_on(@anim);
  }

  > div {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    width: 100%;
    position: relative;
    height: auto;
    z-index: 10;

    > * {
      display: block;
      text-align: left;
      padding: 0 20px 0 8px;
      text-decoration: underline;
      height: 30px;
      font: @font_5;
      color: @color_b1;
      .centery;
      white-space: nowrap;

      &:hover {
        cursor: pointer !important;
      }

      &:first-child:after {
        content: "\f078";
        display: block;
        position: absolute;
        font-size: 11px;
        right: 5px;
        .fas;
        color: @color_b4;
        z-index: 0;
      }
    }
  }

}


// SELECT BOX DARK THEME
.select_box.select_box_theme1 {

  border-radius: @br;
  background: @color_b1;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_b1;


      -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > * {
        color: @color_w;
      }

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b2);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@anim);
  }

  > div {
    z-index: 10;
    border-radius: @br;

    > * {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @font_4;
      text-decoration: none;
      color: @color_b5;

      &:first-child:after {
        content: @fa-var-caret-down;
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .fas;
        color: @color_b4;
      }
    }
  }
}


// SELECT BOX LIGHT THEME
.select_box.select_box_theme2 {

  border-radius: @br;
  background: @color_b1;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_b1;


      -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > * {
        color: @color_w;
      }

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b2);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@anim);
  }

  > div {
    z-index: 10;
    border-radius: @br;

    > * {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @font_4;
      text-decoration: none;
      color: @color_b5;

      &:first-child:after {
        content: @fa-var-caret-down;
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .fas;
        color: @color_b4;
      }
    }
  }
}