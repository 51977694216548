.footer {
  color: @color_b6;
  position: relative;
  background: url("@{img}footer_bg.png") @color_b1 100% 100% no-repeat;
  background-size: auto 420px !important;

  > .resolution-wrap{
    padding-top: @gap*3;
    padding-bottom: @gap*3;
    position: relative;
    z-index: 4;


  }

  .nav-map__columns {
    column-count: 4;
    .col_bb;

    @media only screen and (max-width: 1023.99px),
    only screen and (max-device-width: 1023.99px) {
      column-count: 3;
    }
    @media only screen and (max-width: 779.99px),
    only screen and (max-device-width: 779.99px) {
      column-count: 2;
    }
    @media only screen and (max-width: 479.99px),
    only screen and (max-device-width: 479.99px) {
      column-count: 1;
    }
  }

  .nav-map__title {
    a {
      color: @color_b3;
      text-decoration: none;
      display: flex;
      padding: 4px 0;
      font: @f_reg 22px/120% @font;

      &:hover{
        color: @color_w;
      }
    }
  }

  .nav-map__list {
    a {
      color: @color_b3;
      text-decoration: none;
      display: flex;
      padding: 4px 0;
      font: @f_reg 13px/120% @font;

      &:hover{
        color: @color_w;
      }

      &:last-child {
        margin-bottom: @gap*2;
      }
    }
  }

  .nav-map__soc-networks{
    margin-bottom: @gap;
    display: flex;

    > a{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      text-decoration: none;

      &:not(:last-child){
        margin-right: @gap;
      }

      &::before{
        .fab;
        font-size: 24px;
        color: @color_b2 - 30%;
      }

      &:hover{
        &::before{
          color: @color_w;
        }
      }
    }

    &__instagram::before{content:'\f16d';}
    &__facebook::before{content:'\f39e';}
    &__youtube::before{content:'\f167';}
    &__twitter::before{content:'\f099';}
    &__pinterest::before{content:'\f0d2';}
  }

  &__column{
    padding-left: @gap;
    padding-right: @gap;

    [class*='col-wrap__'] {
      > *{
        padding-left: @gap;
        padding-right: @gap;
      }
    }

    &__title{
      width: 100%;
      font: @f_bold 25px/120% @font;
      text-transform: uppercase;
      position: relative;
      padding-bottom: @gap;
      margin-bottom: @gap;

      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: @color_1;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 100% !important;
      &:not(:last-of-type){
        margin-bottom: @gap*2;
      }
    }
  }

  &__nav-map {
    &__list{
      a{
        display: flex;
        text-align: left;
        text-decoration: none;
        color: @color_w;
        padding: 2px 5px 0 0;
        margin-bottom: 13px;
        margin-left: 0;
        font: @f_reg 17px/110% @font;

        &:hover{
          color: @color_1 + 20%;
        }
      }
    }
  }

  &__contacts{
    .line{
      margin-bottom: @gap;

      .label{
        display: flex;
        width: 100%;
        font: @f_sbold 13px/110% @font;
        color: rgba(@color_w, .7);
      }

      .address{
        font-size: 15px;
      }

      .diary-btn{
        &::before{
          content:'\f5d9';
          .fal;
          margin-right: 10px;
        }
      }

    }
  }



  &__decor-1{
    width: 420px;
    height: 450px;
    position: absolute;
    bottom: -60px;
    transform: translate(40%, 40%);
    fill: rgba(@color_1, .2);
    right: 0;
    pointer-events: none;

    > *{
      transform-origin: 50% 50%;
      animation: spin-speed-1 25s infinite linear;
    }
  }

  &__copyright{
    background: @color_1;
    z-index: 4;
    text-align: right;
    font-size: 13px;
    height: auto;
    min-height: 60px;
    color: @color_b2;
    display: flex;
    align-content: center;
    align-items: center;
    padding-top: @gap;
    padding-bottom: @gap;

    &__text{
      margin-left: auto;
      max-width: calc(100% - 90px);

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        max-width: calc(100% - 60px);
        font-size: 13px;
        line-height: 110%;
      }
    }

    .resolution-wrap{
      display: flex;
      justify-content: left;
      align-items: center;
      align-content: center;
    }
  }

  &__logo{
    width: 76px;
    height: 90px;
    margin-right: @gap;
    position: absolute;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    left: @gap;
    bottom: 10px;
    border: 2px solid @color_w;
    background: @color_1;
    .box-shadow (5px 7px 35px -3px rgba(0,0,0, .3));

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 50px;
      height: 60px;
    }

    svg {
      width: 85%;
      height: 85%;
      fill: @color_w;
    }
  }
}

body.local {
  .footer {
    &__column {
      &__title {
        &::after {
          background: @color_local;
        }
      }
    }

    &__nav-map {
      &__list {
        a {
          &:hover {
            color: @color_local + 20%;
          }
        }
      }
    }

    &__decor-1 {
      fill: rgba(@color_local, .2);
    }

    &__copyright {
      background: @color_local;
    }

    &__logo{
      background-color: @color_local;
    }
  }
}

