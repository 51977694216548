[class*='col-wrap_'] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  margin-left: -@gap;
  margin-right: -@gap;

  & > * {
    min-height: 20px;
    //margin-bottom: @gap*2;
    //padding-left: @gap;
    //padding-right: @gap;

    > * {
      //display: block;
      //width: 100%;
    }
  }


  &.col-wrap__2 {
    > * {
      width: 50%;
    }
  }

  &.col-wrap__3 {
    > * {
      width: 33.33%;
    }
  }

  &.col-wrap__4 {
    > * {
      width: 25%;
    }
  }

  &.col-wrap__5 {
    > * {
      width: 20%;
    }
  }

  &.col-wrap__theme-2 {
    > * {
      width: 33.33%;
    }

    > *:nth-child(4n-1), > *:nth-child(4n-2) {
      width: 66.66%;
    }

    @media only screen and (max-width: 680px),
    only screen and (max-device-width: 680px) {
      > * {
        width: 20%;
      }
      > *:nth-child(4n-1), > *:nth-child(4n-2) {
        width: 80%;
      }
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
      > *:nth-child(4n-1), > *:nth-child(4n-2) {
        width: 100%;
      }
    }

  }

  &.col-wrap__theme-2 {
    > * {
      flex: 0 0 33.33%;
    }

    > *:nth-child(4n-2) {
      flex: 0 0 66.66%;
    }

  }


}

.col_test {
  > * {
    > * {
      // test vis prev
      border: 1 solid @color_b7;
      min-height: 30vh;
      background: @color_w;
      .box-shadow(@shadow);
      border-radius: @br;
    }
  }
}


// COLUMNS STANDART
//=============================================================================
.columns_2 {
  column-count: 2;
  .col_bb;

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    column-count: 1;
    column-gap: @gap*3;
  }
}

.columns_3 {
  column-count: 3;
  column-gap: @gap;
  margin-bottom: @gap*3;
  .col_bb;
}

.columns_4 {
  column-count: 4;
  column-gap: 20px;
  margin-bottom: 20px;
  .col_bb;
}

.col_bb { //block transfer
  -webkit-column-break-inside: avoid !important;
  page-break-inside: avoid !important; /* Makes effect only in Firefox */
  break-inside: avoid !important; /* IE10+, Opera 11.1—12.1 */

  > * {
    width: 100%;
  }
}

