// TABS = TABS
// =======================================
.tabs_container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  *{
    //border: 1px solid red;
  }

  > .tab_nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    > *:hover{
      cursor: pointer;
    }
  }

  > .tabs_box{
    position: relative;

    > *{
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      background: @color_w;
    }

    > *.active{
      position: relative;
      z-index: 2;
    }

  }

}

.tabs_theme1{
  > .tab_nav{

    > *{
      > div{
        padding: 20px;
        border-radius: @br @br 0 0;
        .anim_on(@anim);
        border: 1px solid @color_b6;
        border-bottom: 0;

        > i{
          font-size: 14px;
          margin-right: 10px;
          color: @color_1;
          opacity: 0.7;
        }
      }

      &:hover{
        > div {
          background: @color_b6 + 10%;
        }
      }

      &:not(.active){
        bottom: -3px;
        position: relative;

        > div{
          background: @color_b6;
          color: @color_b2;
        }
      }

      &.active{
        position: relative;
        z-index: 5;
        border: 1px solid @color_b6;
        border-bottom: 0;
        bottom: -1px;
        .box-shadow( 0 -10px 29px -9px rgba(20,50,0,0.08) );

        > div{
          background: @color_w;
          color: @color_b1;

          > i{
            opacity: 1;
          }
        }
      }
    }
  }

  > .tabs_box{
    position: relative;
    border: 1px solid @color_b6;

    > * {
      background: @color_w;
      .box-shadow(@shadow);
      border-radius: 0 0 @br @br;
      overflow: hidden;

      > * {
        padding: 20px;
      }
    }


  }
}