// ---------------------------------- MAIN PAGE
// WELCOME
.section-t1 {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 70vh;
  max-height: 960px;
  margin-bottom: @gap;
  overflow: hidden;

  @media only screen and (max-width: 680px),
  only screen and (max-device-width: 680px) {
    height: 60vh;
    max-height: 760px;
  }

  .resolution-wrap {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    color: @color_w;
    min-height: auto;
  }

  &__content {
    position: relative;
    z-index: 3;
    max-width: 1024px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-left: @gap;
    padding-right: @gap;
  }

  &__title {
    margin-bottom: @gap;
    padding-bottom: @gap;
    position: relative;

    h1 {
      font: @f_bold 60px/110% @font;
      letter-spacing: 3px;
      text-transform: uppercase;
    }

    &::after {
      //content: '';
      display: block;
      width: 68px;
      height: 7px;
      background: @color_w;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &__sub-title {
    p {
      font: @f_reg 26px/110% @font;
    }

    margin-bottom: @gap*2;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    [class*='btn__'] {
      font-size: 18px;
      padding: 0 @gap*2;

      &:not(:last-child) {
        margin-right: @gap;
      }

      i {
        margin-left: 10px;

        &::before {

        }
      }

      height: 60px;
    }
  }

  &__mouse {
    position: absolute;
    z-index: 5;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    &::after {
      content: '\f8cd';
      .fal;
      color: @color_w;
      font-size: 20px;
      animation: slow-jump 6s infinite linear;
    }
  }

  &__bg-slider {
    position: absolute;
    z-index: 1;
    width: 100%;

    &::before { //mask
      content: '';
      pointer-events: none;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      background: rgba(@color_1, .3)
    }

    .item {
      position: relative;
      width: 100%;
      height: 70vh;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .item.slick-active img{
      animation: slow-zoom 16s infinite linear;
    }

    .slick-dots {
      position: absolute;
      max-width: @resolution;
      z-index: 10;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      padding-left: @gap;
      padding-right: @gap;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;

      > li {
        height: auto;

        > button {
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          border: 1px solid rgba(@color_w, 0);
          position: relative;

          &::before {
            content: '';
            width: 12px;
            height: 12px;
            left: 50%;
            top: 50%;
            border-radius: 2px;
            opacity: 1 !important;
            transform: translate(-50%, -50%);
            background: rgba(@color_w, .5 )!important;

          }
        }

        &.slick-active {
          > button {
            border: 2px solid rgba(@color_w, 1);
            border-radius: 2px;
            opacity: 1 !important;
            background: rgba(@color_w, 1 )!important;

            &::before {
              background: rgba(@color_1, 1);
            }
          }
        }
      }
    }
  }

  &__video-bg {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 1;

    video{
      //position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .decor-svg {
    z-index: 3;

    svg {
      fill: @color_w;
    }
  }


  @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
    &__title {
      h1{
        font: @f_bold 28px/110% @font;
      }
    }
    &__sub-title {
      p {
        font: @f_reg 18px/110% @font;
      }
    }
    &__buttons {
      [class*='btn__'] {
        font-size: 15px;
        height: 45px;
        border-radius: 20px;
        padding: 0 @gap;
        text-transform: uppercase;
        margin-bottom: @gap;
        width: auto;
        flex: 0 1 auto;
      }
    }
  }


}

.section-t2{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-bottom: @gap;

  .resolution-wrap {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    color: @color_w;
    min-height: auto;
  }

  &__content {
    position: relative;
    z-index: 3;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    max-width: 1024px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-left: @gap;
    padding-right: @gap;
  }

  &__title {
    margin-bottom: @gap;
    padding-bottom: @gap;
    position: relative;

    h2 {
      font: @f_bold 40px/110% @font;
      letter-spacing: 3px;
      text-transform: uppercase;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_bold 28px/110% @font;
      }
    }

    &::after {
      //content: '';
      display: block;
      width: 68px;
      height: 7px;
      background: @color_w;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &__sub-title {
    p {
      font: @f_reg 20px/110% @font;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_reg 16px/110% @font;
      }
    }

    margin-bottom: @gap*2;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    [class*='btn__'] {
      font-size: 18px;
      padding: 0 @gap*2;

      &:not(:last-child) {
        margin-right: @gap;
      }

      i {
        margin-left: 10px;

        &::before {

        }
      }

      height: 50px;
    }
  }

  &:hover{
    .section-backgroun {
      img {
        transform: scale(1.06);
      }
    }
  }


  &__fact-list{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + @gap*2);

    > *{
      width: calc((100% - @gap*2) / 4  );
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: calc((100% - @gap*2) / 2  );
        margin-bottom: @gap;
      }
      
      p{
        text-align: center;
         font: @f_reg 32px/120% @font;
      }

      @media only screen and (max-width: 960px),
      only screen and (max-device-width: 960px) {
        p{
          font: @f_reg 22px/120% @font;
        }
      }

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        p{
          font: @f_reg 18px/120% @font;
        }
      }
    }

    svg{
      width: 100px;
      height: 100px;
      fill: @color_w !important;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.section-t3{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  //margin-bottom: @gap;

  @media only screen and (max-width: 1023.99px),
  only screen and (max-device-width: 1023.99px) {
    margin-bottom: 0;
  }

  .resolution-wrap {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    color: @color_w;
    min-height: auto;
  }

  &__columns{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + (@gap));

   &[data-columns='2'], &[data-columns='4']{
     .section-t3__columns__item {
       width: 100% / 2;
       margin-bottom: @gap;

       > div{
         padding-bottom: 72%;
       }
       @media only screen and (max-width: 959.99px),
       only screen and (max-device-width: 959.99px) {
         width: 100%;
         margin-bottom: @gap;
       }
     }
   }
    &[data-columns='3'], &[data-columns='6']{
      .section-t3__columns__item {
        width: 100% / 3;
        margin-bottom: @gap;

        @media only screen and (max-width: 1023.99px),
        only screen and (max-device-width: 1023.99px) {
          width: 100%;
          margin-bottom: @gap;
        }
      }
    }

    &__item{
      position: relative;
      padding-left: @gap/2;
      padding-right: @gap/2;

      > div{
        width: 100%;
        position: relative;
        padding-bottom: 86%;

        @media only screen and (max-width: 1023.99px),
        only screen and (max-device-width: 1023.99px) {
          padding-bottom:43%
        }

        @media only screen and (max-width: 679.99px),
        only screen and (max-device-width: 679.99px) {
          padding-top:10%;
          padding-bottom:10%
        }
      }
    }
  }

  &__content {
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    padding: @gap*2;
    z-index: 3;
    width: 100%;
    max-width: 1024px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 679.99px),
    only screen and (max-device-width: 679.99px) {
      position: relative;
      transform:none;
      top: auto;
      left: auto;
    }
  }

  &__icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    svg{
      width: 100px;
      height: 100px;
      fill: @color_w;
    }
  }

  &__title {
    margin-bottom: @gap;
    padding-bottom: 0;
    position: relative;

    h2 {
      font: @f_bold 26px/110% @font;
      text-transform: none;

    }

    &::after {
      //content: '';
      display: block;
      width: 68px;
      height: 7px;
      background: @color_w;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &__sub-title {
    p {
      font: @f_reg 17px/110% @font;
      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_reg 18px/113% @font;
      }
    }

    margin-bottom: @gap;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    [class*='btn__'] {
      font-size: 14px;
      padding: 0 @gap;

      &:not(:last-child) {
        margin-right: @gap;
      }

      i {
        margin-left: 10px;

        &::before {

        }
      }

      height: 44px;
    }
  }
}

.section-t4{
  padding-top: 3%;
  padding-bottom: 3%;
  margin-bottom: @gap;
  background: @color_b7;
}

.section-t5{
  padding-bottom: @gap;

  &__columns{
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gap/2;
    margin-right: -@gap/2;

    &[data-columns='2'], &[data-columns='4']{
      .section-t5__columns__item {
        width: 100% / 2;
        margin-bottom: @gap;
        .custom-video-wrap{
          margin-bottom: 0!important;}

        @media only screen and (max-width: 959.99px),
        only screen and (max-device-width: 959.99px) {
          width: 100%;
          margin-bottom: @gap;
        }
      }
    }
    &[data-columns='3']{
      .section-t5__columns__item {
        width: 100% / 3;
        margin-bottom: @gap;

        @media only screen and (max-width: 1023.99px),
        only screen and (max-device-width: 1023.99px) {
          width: 100%;
          margin-bottom: @gap;
        }
      }
    }

    &__item{
      position: relative;
      padding-left: @gap/2;
      padding-right: @gap/2;

      > div{
        width: 100%;
        position: relative;

        @media only screen and (max-width: 1023.99px),
        only screen and (max-device-width: 1023.99px) {

        }

        @media only screen and (max-width: 679.99px),
        only screen and (max-device-width: 679.99px) {
        }
      }
    }
  }

}

.section-t6{
  padding-bottom: @gap*2;

  .resolution-wrap{
    display: flex;

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      flex-direction: column;
    }
  }

  &__nav{
    flex: 1 0 294px;
    position: relative;
    padding-right: @gap;

    &-container{
      max-width: 294px;
    }

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      flex: 1 1 auto;
      padding-bottom: @gap;
      padding-right: 0;

      &-container{
        position: relative !important;
        top: 0 !important;
      }
    }
  }
  
  &__anchors-title{
     font: @f_reg 26px/120% @font;
    margin-bottom: @gap;
  }
  
  &__anchors-list{
    margin-bottom: @gap;

    a{
      display: flex;
      padding: 4px 0;
      text-decoration: none;

      &::before{
        content: '\f02e';
        color: @color_b3;
        margin-right: 10px;
        position: relative;
        top: 4px;
        font-size: 16px;
        .far;
      }

      &:hover{
        color: @color_2;

        &::before{
          color: @color_2;
        }
      }
    }

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      display: flex;
      flex-wrap: wrap;

      li{
        margin-right: 16px;
        margin-bottom: 8px;

        a{
          padding: 8px 0;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
           font: @f_reg 14px/113% @font;


          &::before{
            top: 0;
            margin-right: 4px;
          }
        }
      }
    }
  }
  

  &__content{

  }
}

.section-t7{
  padding-bottom: @gap*2;

  &__content{
    h1 span{
      color: @color_2;
      font-weight: bold;
    }
  }
}

.section-t8{
  padding-bottom: @gap*2;

}

.section-t9{
  padding-bottom: @gap*2;

}

.section-t10{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-bottom: @gap;

  @media only screen and (max-width: 1023.99px),
  only screen and (max-device-width: 1023.99px) {
    margin-bottom: 0;
  }

  .resolution-wrap {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    color: @color_w;
    min-height: auto;
  }

  &__columns{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + (@gap));

    &__item{
      position: relative;
      padding-left: @gap/2;
      padding-right: @gap/2;
      width: 100% / 3;

      @media only screen and (max-width: 1023.99px),
      only screen and (max-device-width: 1023.99px) {
        width: 100%;
        margin-bottom: @gap;
      }

      > div{
        width: 100%;
        position: relative;
        padding-bottom: 86%;

        @media only screen and (max-width: 1023.99px),
        only screen and (max-device-width: 1023.99px) {
          padding-bottom:43%
        }

        @media only screen and (max-width: 679.99px),
        only screen and (max-device-width: 679.99px) {
          padding-top:10%;
          padding-bottom:10%
        }
      }
    }
  }

  &__content {
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    padding: @gap*2;
    z-index: 3;
    width: 100%;
    max-width: 1024px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 679.99px),
    only screen and (max-device-width: 679.99px) {
      position: relative;
      transform:none;
      top: auto;
      left: auto;
    }
  }

  &__title {
    margin-bottom: @gap;
    padding-bottom: 0;
    position: relative;

    h2 {
      font: @f_bold 26px/110% @font;
      text-transform: none;

    }

    &::after {
      //content: '';
      display: block;
      width: 68px;
      height: 7px;
      background: @color_w;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &__sub-title {
    p {
      font: @f_reg 17px/110% @font;
      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_reg 18px/113% @font;
      }
    }

    margin-bottom: @gap;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    [class*='btn__'] {
      font-size: 14px;
      padding: 0 @gap;

      &:not(:last-child) {
        margin-right: @gap;
      }

      i {
        margin-left: 10px;

        &::before {

        }
      }

      height: 44px;
    }
  }
}



.links-list{
  display: flex;
  flex-direction: column;

  &__image{
    width: 100%;
    height: auto;
    padding-bottom: 50%;
    position: relative;
    overflow: hidden;
    margin-bottom: @gap;

    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  &__list{
    display: flex;
    flex-direction: column;

    a{
      display: flex;
      border: 1px solid @color_b4;
      box-shadow: @shadow_2;
      padding: @gap 40px @gap @gap*1.3;
      margin-bottom: @gap;
      background: @color_w;
      text-decoration: none;
      font: @f_reg 24px/116% @font;
      position: relative;

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        font: @f_reg 18px/116% @font;
      }

      &::after{
        content: '\f178';
        .fal;
        font-size: 24px;
        position: absolute;
        right: 30px;
        color: @color_b3 + 20%;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s linear;
      }

      &:hover{
        background: @color_2;
        color: @color_w;

        &::after{
          color: @color_w;
          right: 25px;
        }
      }
    }
  }

}

.contact{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: @gap*2;

  margin-left: -@gap/2;
  margin-right: -@gap/2;

  &__item{
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 33.33%;

    @media only screen and (max-width: 1240px),
    only screen and (max-device-width: 1240px) {
      width: 33.33%;
    }

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      width: 50%;
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    > div{
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: @gap @gap @gap 56px;
      background: @color_w;
      margin-bottom: @gap;
      border: 1px solid @color_b4;
      box-shadow: @shadow_2, inset 40px 0 0 0 rgba(@color_b4 - 7%, 1);
      position: relative;

      &::before{
        content:'\f007';
        position: absolute;
        top: 16px;
        left:10px;
        .fas;
        font-size: 25px;
        color: @color_b3;
      }
    }
  }

  &__name{
     font: @f_sbold 22px/120% @font !important;
    > * {
      font: @f_sbold 22px/120% @font !important;
    }

    margin-bottom: 4px;
  }

  &__state{
     font: @f_reg 13px/116% @font;
    margin-bottom: 8px;
  }

  &__description{
    font: @f_reg 14px/130% @font !important;

    > *{
      font: @f_reg 14px/130% @font !important;
    }
    margin-bottom: 8px;
  }

  &__mail{
    padding: 5px 0;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    align-content: center;

    &::before{
      content:'\f0e0';
      color: @color_2;
      .fal;
      position: relative;
      top: 1px;
      font-size: 15px;
      margin-right: 8px;
    }
  }
}

.post{
  margin-bottom: @gap*2;
  margin-top: @gap*2;

  &__item{
    margin-bottom: @gap;
    padding: @gap*1.2 @gap @gap*1.5;
    border: 1px solid @color_b4;
    //border-bottom: 1px dashed @color_b3 _ 13%;
    box-shadow: 0 8px 30px -2px rgba(0,0,0,.08);
    background: @color_w;

    &__title{
      > *{
        display: flex;
        flex-wrap: wrap;

        > *{
           font: @f_sbold 24px/120% @font;
          text-decoration: none;
          color: @color_b1;
          position: relative;

          &:not(:last-child){
            padding-right: @gap*2;

            &::after{
              content: '';
              position: absolute;
              right: @gap;
              top: 50%;
              transform: translateY(-50%);
              display: block;
              width: 2px;
              height: 80%;
              background: @color_b3;
            }

          }
        }
      }
    }

    &__content{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.3em;
      height: 3.9em;
      
      @media only screen and (max-width: 960px),
      only screen and (max-device-width: 960px) {
        height: 52px;
      }

      p{
        margin-bottom: 0 !important;
      }
    }
  }


}

.news__slider{

  &__item{
    border-radius: @br;
    overflow: hidden;
    position: relative;
    padding-bottom: 23.3vh;

    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .slick-track {
    display: flex;
    align-items: stretch;
    align-content: stretch;
  }
  .slick-slide {
    margin-left: @gap/2;
    margin-right: @gap/2;
  }
  /* the parent */
  .slick-list {
    margin-left: -@gap/2;
    margin-right: -@gap/2;
  }
}

.content__slider{

  &__item{
    border-radius: @br;
    overflow: hidden;
    position: relative;
    padding-bottom: 23.3vh;

    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .slick-track {
    display: flex;
    align-items: stretch;
    align-content: stretch;
  }
  .slick-slide {
    margin-left: @gap/2;
    margin-right: @gap/2;
  }
  /* the parent */
  .slick-list {
    margin-left: -@gap/2;
    margin-right: -@gap/2;
  }
}

.section-subscribe{

  .section-t2__content {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: @gap*2;
    padding-right: @gap*2;
    //border: 1px solid red;
    text-align: left;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transform: translate( 100%, -50%);
      transition: all .4s ease-out .8s;
      top:50%;
      right: 0;

      display: flex;
      width: 29%;
      height: 90%;
      background: url("@{img}mail.png") 50% 50% no-repeat;
      background-size: 100% auto !important;

      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        display: none;
      }
    }
  }
  &.animate  .section-t2__content {
    &::after{
      opacity: 1;
      visibility: visible;
      transform: translate(0, -50%);
    }
  }

  .section-t2__sub-title{
    max-width: 560px;
  }

  .section-background > div::after{
    background: rgba(@color_1, .5);
  }


  .subscribe_form{
    display: flex;
    justify-content: flex-start;


    .subscribe__wrap{
      display: flex;
      max-width: 560px;
      justify-content: flex-start;
      z-index: 1;
      position: relative;


      input{
        padding-right: 54px !important;
      }
    }

    .subscribe__field{
      width: 100%;
      position: relative;
    }

    .subscribe__button{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1px;
      z-index: 3;

      > *{
        color: @color_1 !important;
        min-width: @elements_h - 2px !important;
        padding: 0 !important;
        border: 0 !important;
        width: @elements_h -2px !important;

        background:none !important;
        box-shadow: none !important;
      }
    }
  }

  input:focus:placeholder-shown~label.label_bottom, input:not(:placeholder-shown)~label.label_bottom, select:focus:placeholder-shown~label.label_bottom, select:not(:placeholder-shown)~label.label_bottom, textarea:focus:placeholder-shown~label.label_bottom, textarea:not(:placeholder-shown)~label.label_bottom{
    color:  @color_w !important;
  }
}
.form_subscribe{
  max-width: 640px;
}

.newsletter {
  display: flex;
  flex-direction: column;

  &__item{
    margin-bottom: @gap;
    display: flex;
    padding: 0;
    border: 1px solid @color_b4;
    box-shadow: @shadow_2;

    @media only screen and (max-width: 540px),
    only screen and (max-device-width: 540px) {
      flex-direction: column;
    }
  }

  &__year{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: @color_w;
    flex: 1 0 120px;
    background: @color_1;
    font: @f_bold 28px/120% @font;
    padding: @gap;

    @media only screen and (max-width: 540px),
    only screen and (max-device-width: 540px) {
      flex: 1 1 auto;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &__list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: @gap;

    a{
      display: flex;
      text-decoration: none;
      width: calc(100% / 6);
      font: @f_reg 18px/120% @font;
      padding: 6px 20px 6px 0;
      margin-bottom: 6px;
      
      @media only screen and (max-width: 1240px),
      only screen and (max-device-width: 1240px) {
        width: calc(100% / 4);
      }
      @media only screen and (max-width: 860px),
      only screen and (max-device-width: 860px) {
        width: calc(100% /3);
      }
      @media only screen and (max-width: 680px),
      only screen and (max-device-width: 680px) {
        width: calc(100% /2);
      }
    }
  }
}

.social-links-container{
  display: flex;
  margin-left: -@gap;
  margin-right: -@gap;
  flex-wrap: wrap;

  &__item{
    display: flex;
    justify-content: flex-start;
    padding-left: @gap;
    padding-right: @gap;
    width: 50%;
    margin-bottom: @gap;

    @media only screen and (max-width: 959.99px),
    only screen and (max-device-width: 959.99px) {
      width: 100%;
      > div{
        align-content: center;
        align-items: center;
        max-width: 640px;
      }
    }

    > div{
      display: flex;

      .dont-wrap{
        min-width: 120px;
        max-width: 120px;
        height: 120px;
        display: flex;
        margin-right: @gap/2;
        align-content: center;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover{
          img{
           transform: scale(1.05);
          }
          &::after{
            background: rgba(@color_1, .08);
          }
        }

        &::after{
          content: '';
          display: flex;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(@color_1, .04);
          border-radius:  @br;
        }

        img{
          transition: all .2s linear;
          width: 90%;
          height: auto !important;
        }
      }
    }


  }

  &__description{
    padding-left: @gap;
     *{
       font-size: 15px !important;
       line-height: 120% !important;
     }
  }
}

.ctct-form-custom{
  .ctct-form-button{
      margin: 0 auto;

    &:hover{
      border: none !important;
    }
  }
}

.g-recaptcha{
  .grecaptcha-badge{
    right: 0 !important;
    transition: all 0.2s linear !important;
    max-width: 70px;
    overflow: hidden;

    &:hover{
      max-width: 249px;
    }
  }
}


.slow-zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
@keyframes slow-zoom {
  .slow-zoom;
}


.slow-jump {
  0%, 100% {
    transform: translateY(-40%);
    opacity: 0;
  }

  10% {
    opacity: .6;
  }

  35% {
    opacity: .6;
  }

  40% {
    transform: translateY(40%);
    opacity: 0;
  }
}
@keyframes slow-jump {
  .slow-jump;
}





// -- 04.10.2022
.section-map {
  padding-bottom: @gap*2;

  .resolution-text-wrap {
    position: sticky !important;
  }

  &__content {
    position: sticky !important;
  }

  &__container {
    position: relative;
    background: @color_b4;
    height: 58vh;
    min-height: 380px;
    max-height: 960px;
    //width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: @gap*2;

    > * {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.wineries-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -@gap;
  margin-right: -@gap;

  &__item{
    display: flex;
    width: 100%;
    position: relative;
    padding-left: @gap;
    padding-right: @gap;
    margin-bottom: @gap*2;
    z-index: 22;
    
    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 100%;
    }

    &-box{
      background: @color_w;
      padding: @gap*1.5;
      box-shadow: @shadow_2;
    }
  }

  &__item-box{
    width: 100%;

    &__content-wrap{
      display: flex;
      flex-direction: row-reverse;
      margin-left: -@gap;
      margin-right: -@gap;

      > *{
        width: 50%;
        padding-left: @gap;
        padding-right: @gap;
      }

      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        flex-wrap: wrap;
        flex-direction: column;

        > *{
          width: 100%;
        }
      }
    }
  }
}

.sub-title-small {
  font: @f_bold 14px/120% @font;
  margin-bottom: 6px;
}

.link-btns-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: @gap;
  flex-direction: column;
  justify-content: flex-start; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios

  @media only screen and (max-width: 580px),
  only screen and (max-device-width: 580px) {
    flex-direction: row;
  }

  > div{
    display: flex;
    min-height: 36px;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios


    margin-bottom: @gap/2;
  }

  > a {
    order: 99;
    min-width: 90px;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    padding: 4px 11px;
    min-height: 24px;
    background: @color_b6;
    color: @color_1;
    margin-right: @gap/2;
    text-decoration: none;
    margin-bottom: @gap/2;

    &::before{
      .fas;
      color: @color_1;
      margin-right: 8px;
      font-size: 11px;
    }
    &.lnk-1::before{
      content: '\f08e';
    }
    &.lnk-2::before{
      content: '\f095';
    }
    &.lnk-3::before{
      content: '\f0e0';
    }

    @media only screen and (max-width: 580px),
    only screen and (max-device-width: 580px) {
      margin-bottom: 0;
      font-size: 13px;
      padding: 3px 9px;
      min-width: 60px;
    }

    &:hover {
      background: @color_1 + 20%;
      color: @color_w !important;
      cursor: pointer !important;

      &::before{
        color: @color_w;
      }
    }
  }
}

.events-list{
  display: flex;
  flex-direction: column;

  &__item{
    margin-bottom: @gap*2;
    position: relative;
    z-index: 2;

    &-box{
      box-shadow: @shadow_2, inset 36px 0 0 0 @color_1;
      background: @color_w;
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      padding: 16px;

      @media only screen and (max-width: 680px),
      only screen and (max-device-width: 680px) {
        flex-direction: column;
        box-shadow: @shadow_2, 0 2px 0 0 @color_1;
      }

      &__image{
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        width: 252px;
        min-width: 252px;
        max-width: 252px;

        @media only screen and (max-width: 680px),
        only screen and (max-device-width: 680px) {
          width:100%;
          min-width: 100%;
          max-width: 100%;
          margin-bottom: @gap;
        }

        img{
          width: 100%;
          height: auto;
        }
      }

      &__text-content{
        width: 100%;
        padding-left: @gap*1.5;

        @media only screen and (max-width: 680px),
        only screen and (max-device-width: 680px) {
          padding-left: 0;
        }

        .event-title{
          font: @f_sbold 22px/120% @font;
        }

        .event-venue{
          display: flex;
          flex-direction: column;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios
          
          
          span[itemprop="name"]{
            font: @f_sbold 16px/120% @font;
            margin-bottom: @gap/2;
          }
          .address{
            position: relative;
            width: 100%;
            padding-left: 28px;
            display: inline-block;
            font: @f_reg 14px/120% @font;
            min-height: 26px;
            padding-top: 4px;

            &::before{
              content: '\f3c5';
              position: absolute;
              left: 0;
              top: 0;
              width: 24px;
              height: 24px;
              display: flex !important;
              justify-content: center; // align X
              align-items: center; // align Y
              align-content: center; // align Y ios
              .fas;
              color: @color_b3;
            }
          }
        }

        .event-date__wrap{
          display: flex;
          padding-left: 28px;
          padding-top: 4px;
          position: relative;
          margin-bottom: @gap/2;

          &::before{
            content: '\f133';
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            display: flex !important;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios
            .fas;
            color: @color_b3;
          }

          > *{
            padding:  @gap/2;
            display: flex;
            margin-right: @gap/2;
            background: @color_b5;
            font: @f_reg 14px/120% @font;
          }
        }

        .event-price{
          display: flex;
          padding-left: 28px;
          padding-top: 4px;
          position: relative;
          margin-bottom: @gap/2;
           font: @f_sbold 16px/120% @font;

          &::before{
            content: '\f02b';
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            display: flex !important;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios
            .fas;
            color: @color_b3;
          }
        }

        .description{
          display: flex;
          margin-bottom: @gap;
          position: relative;

          .box-crop-text{
            overflow: hidden;
            position: relative;
            max-height: 98px;
            z-index: 1;
            transition: all .8s linear;

            p{
              font-size: 14px;
            }
          }

          .more-btn{
            z-index: 3;
            display: flex;
            padding: 0 2px 0 8px;
            background: @color_w;
            position: absolute;
            bottom: 0;
            right: 0;
            color: @color_1 + 20%;
            text-decoration: underline;
            font-size: 14px;

            span:nth-child(1){
              display: inline-block;
            }
            span:nth-child(2){
              display: none;
            }
            &:hover{
              color: @color_1 + 40%;
              cursor: pointer;
            }
          }

          &.unfolded{
            .box-crop-text{
              max-height: none;
            }
            .more-btn{
              span:nth-child(1){
                display: none;
              }
              span:nth-child(2){
                display: inline-block;
              }
            }
          }
        }

      }
      // btn learn more
      .event-more{
        display: flex;
        justify-content: flex-end; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios

        a{
          padding: 6px @gap*1.5;
          box-shadow: inset 0 0 0 2px @color_1;
          text-transform: capitalize;
          transition: all .2s linear;
          text-decoration: none !important;
          background: @color_w;
          display: flex;
          min-height: 40px;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          font-size: 14px;

          &::after{
            content: '\f061';
            .fal;
            margin-left: @gap;
            color: @color_1;
          }

          &:hover{
            background: @color_w;
            color: @color_1;
            box-shadow: inset 0 0 0 4px @color_1;
          }
        }
      }
    }
  }
}

.load-more-btn{
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  a{
    padding: 6px @gap*1.5;
    box-shadow: inset 0 0 0 2px @color_1;
    text-transform: capitalize;
    transition: all .2s linear;
    text-decoration: none !important;
    background: @color_w;
    display: flex;
    min-height: 50px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    &:hover{
      background: @color_w;
      color: @color_1;
      box-shadow: inset 0 0 0 4px @color_1;
    }

    &::after{
      content: '\f2f9';
      .far;
      margin-left: @gap;
      color: @color_1;
    }
  }
}



