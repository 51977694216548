
// BUTTONS style
//=============================================================================
.btn__theme-1 {
  display: flex;
  border: none;
  background: none;
  outline: none;
  border-radius: @br !important;
  font: @font_6;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 @gap*1.5;
  background: @color_1;
  text-align: center;
  text-decoration: none;
  color: @color_w;
  white-space: nowrap;
  .anim_on(@anim);
  box-shadow: 0 4px 18px 0 rgba(@color_1, .35);

  > *:not(:last-child) {
    margin-right: 7px;
  }

  > i{
    margin-left: 8px;
  }

}

.btn__theme-2 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  // border: 1px solid @color_1;
  box-shadow: inset 0 0 0 2px rgba(@color_1, 1);
  background: none;
  color: @color_1 !important;
  white-space: nowrap;
  .anim_on(@anim);
  border-radius: @br;

  > *:not(:last-child) {
    margin-right: 7px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn__theme-3 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  border: 2px solid @color_w;
  color: @color_b1;
  background: @color_w;
  white-space: nowrap;
  .anim_on(@anim);
  border-radius: @br;
  .box-shadow(@shadow);

  &:active {
    transform: translateY(1px);
  }

}

.btn__theme-4 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  border: 2px solid @color_w;
  color: @color_w;
  background: none;
  white-space: nowrap;
  transition: all .2s linear !important;
  border-radius: @br;

  &:active {
    transform: translateY(1px);
  }

}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  .no_select;

  > * {
    i {
      margin-right: 10px;
    }
  }
}

.style-btn__1 {
  > * {
    .btn__theme-1;
  }
}

.style-btn__2 {
  > * {
    .btn__theme-2;
  }
}

.style-btn__3 { // white
  > * {
    .btn__theme-3;
  }
}


.group-btns{
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;

  > *:not(:last-of-type){
    margin-right: @gap;
  }

}

// HOVER not mobile
body:not(.mobile) {
  .style-btn__1 > *:not([disabled]):hover,
  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2;
    color: @color_w;
    box-shadow: 0 6px 20px 0 rgba(@color_1, .55);
  }

  .style-btn__2 > *:not([disabled]):hover,
  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    //background: @color_2;
    box-shadow: inset 0 0 0 5px rgba(@color_1, 1);
    color: @color_1 !important;
  }

  .style-btn__3 > *:not([disabled]):hover,
  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_w;
    color: @color_b1 !important;
  }

  .style-btn__4 > *:not([disabled]):hover,
  .btn__theme-4:not([disabled]):hover {
    cursor: pointer !important;
    background: none;
    box-shadow: inset 0 0 0 5px rgba(@color_w, 1);
    color: @color_w !important;
  }
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btn_l {
  display: flex;
  justify-content: flex-start !important;
}

.btn_c {
  display: flex;
  justify-content: center !important;
}

.btn_r {
  display: flex;
  justify-content: flex-end !important;

}


// SCROLL UP BUTTON
// =======================================
#go-top {
  position: fixed;
  bottom: 65px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;

  @media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
    bottom: 85px;
  }

  > * {
    display: block;
    text-align: center;
    cursor: pointer;
    width: inherit;
    height: inherit;
    background: @color_1;
    border: 1px solid @color_1;

    collapse: @color_2;
    border-radius: @br;
    transform: scale(0.95);
    box-shadow: 0 5px 17px 0 rgba(@color_1, .8);
    text-decoration: none;
    .centerxy;

    &::after {
      content: @fa-var-arrow-up;
      font-size: 15px;
      .fal;
      .centerxy;
      color: @color_w;

    }

    &:active {
      transform: scale(0.92);
    }
  }
}

body.local{
  #go-top {
    > * {
      background: @color_local;
      border: 1px solid @color_local;
      box-shadow: 0 5px 17px 0 rgba(@color_local, .8);
    }
  }
}

.btn-more{
  display: inline-flex;
  min-height: @elements_h /1.2;
  box-shadow: inset 0 0 0 1px @color_1;
  color: @color_1;
  font: @f_reg 15px/110% @font;
  border-radius: @br;
  padding: 10px 16px;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;

  &:focus, &:active{
    box-shadow: inset 0 0 0 1px @color_1 !important;
    color: @color_1 !important;

    &::after{
      color: @color_1 !important;
    }
  }

  &:hover{
    box-shadow: inset 0 0 0 100px @color_1;
    color: white;

    &::after{
      color: white;
    }
  }
  &:active{
    transform: translateY(2px);
  }

  &::after{
    content: '\f178';
    .fal;
    margin-left: 10px;
  }
}


