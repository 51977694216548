.svg-sprites {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

// LOGO
.logo {
  display: block;

  svg {
    width: 100%;
    height: 100%;
  }
}

.svg-logo {
  shape-rendering:geometricPrecision;
  text-rendering:geometricPrecision;
  image-rendering:optimizeQuality;
  fill-rule:evenodd;
  clip-rule:evenodd;

  .logo__group{
    .logo__symbol{
      fill: @color_1;
    }
  }
}

.svg-logo-symbol {
  shape-rendering:geometricPrecision;
  text-rendering:geometricPrecision;
  image-rendering:optimizeQuality;
  fill-rule:evenodd;
  clip-rule:evenodd;

  .logo__symbol{
    fill: @color_1;

    > *{
      fill-rule:nonzero;
    }
  }
}

.nowrap{
  white-space: nowrap;
  font-weight: inherit !important;
  font-size: inherit !important;
  text-decoration: inherit !important;
  text-transform: inherit !important;
}

// TITLES
.title {
  position: relative;
  text-align: center;

  > * {
    font: @f_bold 43px/110% @font;
    text-transform: uppercase;
    padding-bottom: @gap;
    margin-bottom: @gap;

    &::after {
      content: '';
      display: block;
      width: 68px;
      height: 5px;
      background: @color_1;
      position: absolute;
      bottom: -2.5px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      font: @f_bold 35px/110% @font;
    }

    @media only screen and (max-width: 640px),
    only screen and (max-device-width: 640px) {
      font: @f_bold 25px/110% @font;
    }
  }
}

// СЛАЙДЕР НА 3 БЛОКА
.gallery__slider{
  position: relative;

  button.slick-prev.slick-arrow {
    left: 34%;
  }

  button.slick-next.slick-arrow {
    right: 34%;
  }

  button.slick-arrow {
    transform-origin: 50% 50%;
  }

  button.slick-arrow:hover {
    transform: translateY(-50%) scale(1.2);
  }

  .slick-track {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .item {
    outline: none !important;

    .img-box {
      background: @color_b4;
    }

    &.slick-center {
      position: relative;
      z-index: 99;

      .img-box-wrap {
        .img-box {
          .img-wrap {
            padding-bottom: 85%;
            //box-shadow: @shadow;
          }
        }
      }
    }

    .img-box-wrap {
      position: relative;
      display: flex;
      width: 100%;
      padding-bottom: 80%;

      .img-box {
        position: absolute;
        width: calc(100% - (@gap * 2));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        height: auto;
        display: flex;
        align-content: center;
        align-items: center;
        box-shadow: 0 10px 35px -3px rgba(@color_b1, .3);
        border-radius: @br;
        transition: all .2s linear;

        .img-wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-bottom: 65%;
          border-radius: @br;
          transition: all .15s linear;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
          }
        }
      }
    }

    .img-name{
      width: 100%;
      position: absolute;
      z-index: 5;
      bottom: 0;
      border-radius: 0 0 @br @br;
      display: flex;
      align-content: flex-end;
      height: 105px;
      align-items: flex-end;
      justify-content: center;
      padding: 0 16px 20px 16px;
      background: @color_b1;
      background: linear-gradient(180deg,
      rgba(23, 25, 73,0) 0%,
      rgba(23, 25, 73,1) 100%);
      color: @color_w;
       font: @f_sbold 20px/120% @font;
    }

  }
  
  @media only screen and (max-width: 960px),
  only screen and (max-device-width: 960px) {

    .slick-track {
      padding-top: @gap;
      padding-bottom: @gap;
    }

    button.slick-prev.slick-arrow {
      left: 6%;
    }

    button.slick-next.slick-arrow {
      right: 6%;
    }

    .item {
      outline: none !important;

      .img-box {
        background: @color_b4;
      }

      .img-box-wrap {
        .img-box {
          box-shadow: none;
        }
      }

      .img-name{
        border-radius: 0 0 @br/2 @br/2;
        font: @f_sbold 17px/120% @font;
      }

    }
  }

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    .item {
      .img-box-wrap {
        .img-box {
          border-radius: @br/2;

          .img-wrap {
            border-radius: @br/2;

          }
        }
      }
    }

    button.slick-prev.slick-arrow {
      left: 9px;
    }

    button.slick-next.slick-arrow {
      right: 9px;
    }
  }
}

.news__slider, .content__slider{
  position: relative;

  button.slick-prev.slick-arrow {
    left: -1%;
  }

  button.slick-next.slick-arrow {
    right: -1%;
  }

  button.slick-arrow {
    transform-origin: 50% 50%;
  }

  button.slick-arrow:hover {
    transform: translateY(-50%) scale(1.2);
  }

  .slick-track {
    //padding-top: 60px;
    //padding-bottom: 60px;
  }

  .item {
    outline: none !important;

    .img-box {
      background: @color_b4;
    }

    &.slick-center {
      position: relative;
      z-index: 99;

      .img-box-wrap {
        .img-box {
          .img-wrap {
            padding-bottom: 85%;
            //box-shadow: @shadow;
          }
        }
      }
    }

    .img-box-wrap {
      position: relative;
      display: flex;
      width: 100%;
      padding-bottom: 80%;

      .img-box {
        position: absolute;
        width: calc(100% - (@gap * 2));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        height: auto;
        display: flex;
        align-content: center;
        align-items: center;
        box-shadow: 0 10px 35px -3px rgba(@color_b1, .3);
        border-radius: @br;
        transition: all .2s linear;

        .img-wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-bottom: 65%;
          border-radius: @br;
          transition: all .15s linear;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
          }
        }
      }
    }

    .img-name{
      width: 100%;
      position: absolute;
      z-index: 5;
      bottom: 0;
      border-radius: 0 0 @br @br;
      display: flex;
      align-content: flex-end;
      height: 105px;
      align-items: flex-end;
      justify-content: center;
      padding: 0 16px 20px 16px;
      background: @color_b1;
      background: linear-gradient(180deg,
      rgba(23, 25, 73,0) 0%,
      rgba(23, 25, 73,1) 100%);
      color: @color_w;
      font: @f_sbold 20px/120% @font;
    }

  }

  @media only screen and (max-width: 960px),
  only screen and (max-device-width: 960px) {

    .slick-track {
      padding-top: @gap;
      padding-bottom: @gap;
    }

    .item {
      outline: none !important;

      .img-box {
        background: @color_b4;
      }

      .img-box-wrap {
        .img-box {
          box-shadow: none;
        }
      }

      .img-name{
        border-radius: 0 0 @br/2 @br/2;
        font: @f_sbold 17px/120% @font;
      }

    }
  }

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    .item {
      .img-box-wrap {
        .img-box {
          border-radius: @br/2;

          .img-wrap {
            border-radius: @br/2;

          }
        }
      }
    }
  }
}

// Video
.custom-video-wrap {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: @br;
  padding-bottom: 56%;
  background-color: @color_w;
  .box-shadow(@shadow);
  margin-bottom: @gap * 2;

  .video__media {
    position: absolute;
    top: -1%;
    left: 0;
    object-fit: cover;
    width: 102%;
    height: 102%;
    border: none;
  }

  .video__button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    border: none;
    transform: translate(-50%, -50%);
    //display: flex;
    padding: 0;
    background: transparent;
    align-items: center;
    align-content: center;
    justify-content: center;
    outline: none;
    display: none;
    opacity: .5;

    &::after{
      content: '\f04b';
      .fas;
      font-size: 60px;
      color: @color_1;
    }

    &:hover{
      &::after{
        color: @color_2;
      }
    }

    svg {
      width: 100%;
      height: auto;
    }

    &-icon {
      fill: #fff;
    }

    @media only screen and (max-width: 740px),
    only screen and (max-device-width: 740px) {
      width: 60px;
      height: 60px;
      opacity: 1;
      box-shadow: @shadow;
    }

  }

  &:hover .video__button,
  .video__button:focus .video__button-shape {
    opacity: 1;
  }

  &.video--enabled .video__button {
    display: flex;
  }

  &.video--enabled {
    cursor: pointer;
  }
  
  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    border-radius: @br/2;
  }
}

.section-background{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;

  &.background__resolution{
    width: calc(100% - (@gap*2));
    left: 50%;
    transform: translateX(-50%);
  }

  > div{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden !important;

    &::after{
      content: '';
      pointer-events: none;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      background: rgba(@color_1, .3)
    }
    img{
      transition: all .2s linear;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      top: 0;
      left: 0;
      z-index: 1;
    }
  }


}

.padding-top_x2{
  padding-top: @gap*2;
}

// ---------------------------------- 404
.section__error {
  display: flex;
  height: 100%;
  min-height: 70vh;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: @gap;
  padding-right: @gap;
  padding-top: @gap*2;

  .section__error__number {
    font-size: 120px;
    color: @color_1;
    font-weight: @f_bold !important;
    line-height: 150%;
    display: flex;
    text-align: center;

    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      font-size: 80px;
    }
  }

  .error__details {
    text-align: center;
  }

}

.dark-glass{
  display: block;
  position: absolute;
  background: rgba(0,0,0, .65);
  background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%);
  width: 80%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;

}

// DECOR
.decor{
  position: relative;

  &-svg{
    position: absolute;
    display: block;
    width: 100%;
    padding-bottom: 11.36%;
    pointer-events: none;
    z-index: 4;

    svg{
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      fill: @color_b1;
    }
  }
}
.decor__bottom{
  //padding-bottom: 11.36%;
  .decor-svg{
    bottom: -1px;
  }
}
.decor__top{
  .decor-svg{
    top: 0;
  }
}
.decor__reverse{
  .decor-svg{
    transform: scaleX(-1);
  }
}

// ЛЕТАЮЩИЕ КВАДРАТЫ
.flying-square{
  position: absolute;
  display: block;
  z-index: 0;
  pointer-events: none;

  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50% !important;
    border-radius: @br;
    background: @color_1;
    animation: spin-speed-1 30s infinite linear;
  }


}
@keyframes spin-speed-1 {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}


// SLIDER
.big-slider {
  position: relative;
  width: auto;
  border-radius: @br;
  margin-bottom: @gap;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;

  p {
    margin-bottom: 0;
  }

  &::after {
    content: @fa-var-search-plus;
    font-size: 20px;
    .fas;
    color: @color_1;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    .anim_on(@anim);
    animation-delay: 1.0s;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    margin-right: 15px;
    margin-left: 15px;
  }

  a {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 70vh;
    padding-bottom: 66.6%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      position: absolute;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      width: 100%;
      height: 100%;
      font-family: 'object-fit: cover;';
    }
  }

}

.img-wrap{
  background: @color_b4 url("@{img}image-bg.png") 50% 50% no-repeat;
  background-size: 20% auto !important;
}


// slick tools
button.slick-arrow {
  display: block;
  top: 50%;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: rgba(@color_1, 1) !important;
  border-radius: @br;
  .centerxy;
  transform: translateY(-50%);

  &:hover {
    background: rgba(@color_1, 1);
    box-shadow: 0 7px 20px -2px  rgba(@color_1, .4);
  }

  &::before {
    position: relative;
    .fas;
  }
}
button.slick-prev.slick-arrow {
  left: 5px;

  &::before {
    content: "\f053";
    left: -2px;
  }
}
button.slick-next.slick-arrow {
  right: 5px;

  &::before {
    content: "\f054";
    right: -2px;
  }
}
button.slick-lightbox-close {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  z-index: 8;

  &::before {
    content: @fa-var-times;
    position: relative;
    font-size: 22px;
    color: @color_1;
    .fas;
  }
}

.slick-lightbox {
  .slick-lightbox-slick-item-inner {
    width: 100%;

    img {
      width: auto;
      max-width: 980px;

      @media only screen and (max-width: 1024px),
      only screen and (max-device-width: 1024px) {
        max-width: 100% !important;
      }
    }
  }
}

.crumbs{
  margin-top: @gap;

  .resolution-text-wrap, .resolution-wrap{

    display: flex;
    margin-bottom: @gap;

    > *{
      font: @f_reg 13px/120% @font;
      padding-right: 23px;
      position: relative;
      padding-top: @gap;
      padding-bottom: @gap;

      &:not(:last-child){
        &::after{
          content: '\f105';
          position: absolute;
          .fal;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color:@color_b3;
        }
      }
    }
  }
}

// ПАГИНАТОР
.paginator{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: @gap*2;
  padding-top: @gap;
  padding-bottom: @gap;

  &__item{
    margin: 0 @gap/2;

    &__btn-prev{
      background: @color_1;

      &::before{
        color: @color_w !important;
        content: '\f060';
        .fal;
      }
    }

    &__btn-next{
      background: @color_1;

      &::before{
        color: @color_w !important;
        content: '\f061';
        .fal;
      }
    }

    a{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      text-align: center;
      font: @f_reg 17px/120% @font;
      color: @color_1;
      width: @elements_h;
      height: @elements_h;
      border-radius: 100%;
      box-shadow: inset 0 0 0 1px @color_1, 0 5px 20px -3px rgba(@color_1, .3);
      text-decoration: none;

      &:not(.active):hover{
        transform: scale(1.13);
        box-shadow: inset 0 0 0 2px @color_1, 0 7px 20px 0 rgba(@color_1, .4);
      }

      &.active{
        background: @color_1;
        color: @color_w;
      }


    }

    div{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: @color_1;
      width: auto;
      height: @elements_h;
    }
  }

  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    &__item{
      margin: 0 @gap/3;

      a{
        font: @f_reg 15px/120% @font;
        width: @elements_h/1.3;
        height: @elements_h/1.3;

        &:not(.active):hover{
          transform: scale(1.13);
          box-shadow: inset 0 0 0 2px @color_1, 0 7px 20px 0 rgba(@color_1, .4);
        }

        &.active{
          background: @color_1;
          color: @color_w;
        }

      }

      div{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        color: @color_1;
        width: auto;
        height: @elements_h;
      }
    }
  }
}

// Показать ещё
.show-more{
  /*
  &:not(.active){
    visibility: hidden;
    opacity: 0;
  }
  */
  width: 100%;
  margin-bottom: @gap*3;
  justify-content: center;

  &._sending{
    .show-more__btn{
      &::before{
        animation: spin 2s infinite linear;
      }
    }
  }
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  &__btn{
    display: inline-flex;

    &::before{
      content: '\f2f9';
      .fas;
      margin-right: 10px;
    }
  }
}

/*
.load-more-container{
  [class*='__item']:not(:first-child):not(.active){
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: all .2s linear;
  }
  [class*='__item'].active{
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}
*/

.slick-lightbox-slick-img{
  border-radius: @gap;
}