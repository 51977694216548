// 404 PAGE
// =======================================
.error-page{
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;

  .error-page__number{
    font-size: 68px;
    line-height: 68px;
    color: @color_1;
    font-weight: @f_bold;
    margin-bottom: 20px;
  }
  .error-page__description{
    margin-bottom: 20px;
  }


}



