// Стили Выделения на странице
::selection {
  background: @color_1;
  color: @color_w;
}
::-moz-selection {
  background: @color_1;
  color: @color_w;
}

// Общие параметры анимации
//=============================================================================
@anim: all .2s ease-out;
.anim_on(@anim){
  transition: @anim;
}

// ЦВЕТА
//=============================================================================
// основные цвета:
@color_1: #003c85; //#1AA2BF;
@color_2: @color_1 - 40%;
@color_3: #FFB906;
@color_4: #D12E2E;
@color_5: #c6e3a9;

// градиент серого:
@color_b1: #313131;
@color_b2: #8a94a9;
@color_b3: #cbccde;
@color_b4: #f2f2f9;
@color_b5: rgba(248, 248, 248, 1);
@color_b6: rgba(248, 248, 248, 1);
@color_b7: rgba(248, 248, 248, 1);
@color_w: #fff;

@color_local:  #1AA2BF;
@color_frd:  #45850d;
@color_frc:  #12886b;
@color_saltpoint:  #5a288e;
@color_programs:  #99A98D;

// Основные шрифты
//=============================================================================
@font: 'Montserrat', Helvetica, Arial, sans-serif;
html, body {
  font: @font_6;
  line-height: 130%;
  color: @color_b1;
}

@font_1: @f_sbold 35px/120% @font;
@font_2: @f_sbold 25px/120% @font;
@font_3: @f_sbold 23px/120% @font;
@font_4: @f_reg 25px/140% @font;
@font_5: @f_reg 21px/140% @font;
@font_6: @f_reg 17px/140% @font;
@font_7: @f_reg 15px/140% @font;
@font_8: @f_reg 13px/140% @font;
@font_9: @f_reg 11px/140% @font;

@f_reg: 400;
@f_sbold: 600;
@f_bold: 800;


// bind objects
//=============================================================================
// BORDER RADIUS
@br: 1px;

// BOX SHADOW
@shadow: 0 7px 35px -3px rgba(0,76,175, .2);
@shadow_2: 0 7px 27px -3px rgba(0,0,0,.07);
.box-shadow (@shadow){
  box-shadow: @shadow;
}

// ВЫСОТА КНОПОК, ИНПУТОВ, И Др. ЭЛЕМЕНТОВ
//=============================================================================
@gap: 16px;
@elements_h: 52px;
@img: '/assets/layouts/img/';
@gallery: '/assets/layouts/gallery/';

// TEXT ALIGN
.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

// HOVER EFFECTS
.h_zoom(@zoom, @sp) {
  transform: scale(@zoom);
  transition: all @sp ease-out;
}

// NO SELECT TEXT
.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}


// CUSTOM SCROLLBAR
//=============================================================================
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: @color_b7;
}

@scroll_thumb: @color_b3;
@scroll_thumb_s: rgba(@color_b5, 0);
::-webkit-scrollbar-thumb {
  background: @scroll_thumb;
  border-radius: @br;

  &:hover {
    background: @scroll_thumb - 20%;
  }

  &:active {
    background: @color_1;
  }
}


// ANIMATION HOVER LINKS and BUTTONS
//=============================================================================
select,
a, button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  transition: all .25s ease-in-out;
}
a{
  color: @color_1;

  &:hover{
    color: @color_2;
  }
}


// ALIGN CENTER CSS
//=============================================================================
.imgfit {
  overflow: hidden;

  img {
    -o-object-fit: cover !important;
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
  }
}


// FLEX-BOX ALIGN
.centerxy {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerx {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centery {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}













