// STARS RATING
/*
.rating {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  //unicode-bidi: bidi-override;
  //direction: rtl;
  //width: 8em;

  > span{
    display: none;
    margin-left: 10px;
    font: @font_7;

    &::before{
      content: @fa-var-user;
      .fas;
      font-size: 13px;
      left: -3px;
      color: @color_b4;
    }
  }

  &[data-user-rate] span{
    display: inline-block;
  }

  >label {
    display: inline-block;
    font-size: 0;

    >input {
      position: absolute;
      left: -999999px;
    }

    &:before {
      position: relative;
      font-size: 16px;
      .fas;
      display: block;
      content: "\f005";
      color: @color_b5;
      //background: -webkit-linear-gradient(-45deg, #d9d9d9 0%, #b3b3b3 100%);
      //background: @color_b5;
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
}
.rating > label:hover:before,
.rating > label:hover ~ label:before,
.rating > label.selected:before,
.rating > label.selected ~ label:before {
  color: @color_4 !important;
  //background: @color_4;
  //background: -webkit-linear-gradient(-45deg, #fcb551 0%, #d69a45 100%);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}
.rating > label:hover:before,
.rating > label:hover ~ label:before{
  color: @color_4 + 40% !important;
}

*/
.rating,
.rating_info {
  display: flex;
  flex-direction: row-reverse !important;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  //unicode-bidi: bidi-override;
  //direction: rtl;
  //width: 8em;
  margin-top: 5px;
  margin-bottom: 5px;

  white-space: nowrap !important;

  > span {
    display: none;
    margin-left: 10px;
    font: @font_7;

    &::before {
      content: @fa-var-user;
      .fas;
      left: -3px;
      color: @color_b5;
      margin-right: 4px;
    }
  }

  &[data-user-rate] span {
    display: inline-block;
  }

  > a {
    display: inline-block;
    margin-left: 5px;
    font: @font_7;
    color: @color_b1;
    text-decoration: none;
    border-bottom: 1px dashed @color_b1;
  }

  > div {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    top: -2px;


    > div {
      display: inline-block;
      margin-right: 2px;

      &:before {
        position: relative;
        font: @font_7;
        .fas;
        content: "\f005";
        color: @color_b5;
      }
    }
  }
}
.rating {
  > div {
    > div:hover:before,
    > div:hover ~ div:before,
    > div.selected:before,
    > div.selected ~ div:before {
      color: @color_4 !important;
      cursor: pointer;
    }

    > div:hover:before,
    > div:hover ~ div:before {
      color: @color_4 + 40% !important;
    }
  }
}
.rating_info {
  > div {
    > div.selected:before,
    > div.selected ~ div:before {
      color: @color_4 !important;
    }
  }
}