.font_8, .font_8 * {
  font: @font_8;
}

.font_7, .font_7 * {
  font: @font_7;
}

.font_6, .font_6 * {
  font: @font_6;
}

.font_5, .font_5 * {
  font: @font_5;
}

.font_4, .font_4 * {
  font: @font_4;
}

.font_3, .font_3 * {
  font: @font_3;
}

.font_2, .font_2 * {
  font: @font_2;
}

.font_1, .font_1 * {
  font: @font_1;
}

.caps {
  text-transform: uppercase;
}

.headernames_box {

  > h1 {
    .font_2;
    margin-bottom: 10px;
  }

  > h2 {
    .font_2;
    margin-bottom: 10px;
  }

  > h3 {
    .font_3;
    margin-bottom: 10px;
  }

  > h4 {
    .font_4;
    margin-bottom: 10px;
  }

}


// TEXT BOX
.text-box , .text-wrap{

  .row, .logos{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -@gap/2 !important;
    margin-right: -@gap/2 !important;

    &[data-columns='1'] > *{
      width: 100% !important;
    }
    &[data-columns='2'] > *{
      width: 50% !important;

      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: 100% !important;
      }
    }
    &[data-columns='3'] > *{
      width: 33.33% !important;
      
      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        width: 100% !important;
      }
    }
    &[data-columns='4'] > *{
      width: 25% !important;

      @media only screen and (max-width: 780px),
      only screen and (max-device-width: 780px) {
        width: 50% !important;
      }
      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: 100% !important;
      }
    }
    &[data-columns='more'] > *{
      width: 33.33% !important;

      @media only screen and (max-width: 780px),
      only screen and (max-device-width: 780px) {
        width: 50% !important;
      }
      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: 100% !important;
      }
    }
  }
  .row{
    margin-bottom: @gap;

    > .column{
      padding-left: @gap/2 !important;
      padding-right: @gap/2 !important;

      display: flex;
      flex-wrap: wrap;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: @gap;

      > p{
        width: 100%;
        margin-bottom: 8px;
      }

      figure{
        width: 100%;
        p{
          margin-bottom: 8px;
        }
        a img{
          transition: all 0.2s linear;
        }
        a{
          &:hover{
            img{
              transform: scale(1.03);
            }
          }
        }
        .img-wrap{
          padding-bottom: 75%;
        }
      }

      figcaption{
         font: @f_reg 14px/120% @font;
      }

      .img-wrap{
        position: relative;
        display: flex;
        overflow: hidden;
        padding-bottom: 60%;
        width: 100%;
        height: auto;

        img{
          position: absolute;
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          left: 0;
          top: 0;
        }
      }


    }
  }
  .logos{
    margin-bottom: @gap*2;

    .img-wrap{
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
      background: none !important;

      img{
        position: relative;
        width: auto !important;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }

  .my-img{
    padding: 0;
  }

  .video-wrap{
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    align-content: center;
    align-items: center;
    justify-content: center;

    iframe{
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .title {
    > * {
      .font_1;
      padding-left: 20px;
      position: relative;
      margin-bottom: 10px;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        background: @color_1;
        display: block;
        width: 2px;
        height: calc(100% - 16px);
        transform: translateY(-50%);
      }
    }
  }

  h1{
    font: @f_bold 40px/120% @font;
    letter-spacing: 0.14px;
    position: relative;
    margin-bottom: 10px;
    
    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      font: @f_bold 28px/120% @font;
    }
  }

  h2{
    font: @f_sbold 32px/120% @font ;
    position: relative;
    margin-bottom: 10px;

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      font: @f_sbold 24px/120% @font;
    }
  }
  h3{
    font: @f_sbold 22px/120% @font !important;
    position: relative;
    margin-bottom: 10px;

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      font: @f_sbold 18px/120% @font;
    }

    a{
      font: @f_sbold 22px/120% @font !important;
      color: @color_b1;

      @media only screen and (max-width: 720px),
      only screen and (max-device-width: 720px) {
        font: @f_sbold 18px/120% @font;
      }

      &:hover{
        color: @color_2;
      }
    }
  }
  h4{
    font: @f_sbold 19px/116% @font !important;
    position: relative;
    margin-bottom: 10px;

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      font: @f_sbold 17px/120% @font;
    }

    a{
      font: @f_sbold 19px/116% @font !important;
      color: @color_1;

      @media only screen and (max-width: 720px),
      only screen and (max-device-width: 720px) {
        font: @f_sbold 17px/120% @font;
      }

      &:hover{
        color: @color_2;
      }
    }
  }

  .date{
    color: @color_b2;
    margin-bottom: @gap;

    &::before{
      content: '\f783';
      color: @color_b3;
      .fal;
    }
  }

  .sub-title {
    > * {
      .font_3;
      margin-bottom: 10px;
    }
  }

  .sub-sub-title {
    .font_4;
  }

  p{
    .font_6;
    margin-bottom: @gap;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
       font: @f_reg 15px/120% @font;
    }
  }

  .big-img{
    //overflow: hidden;
    max-width: 100%;
    display: flex;
    border-radius: @br;
    .box-shadow(@shadow);
    //padding-bottom: 56.25%;
    position: relative;
    margin-bottom: @gap;

    img{
      width: 100%;
      height: 100%;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      font-family: 'object-fit: cover;';
      //position: absolute;
      //top: 0;
      //left: 0;
    }
  }

  [class*='prev-img']{
    width: 33.33%;
    display: flex;

    > div{
      overflow: hidden;
      display: flex;
      flex: 1 0 120px;
      width: 100%;
      padding-bottom: 66.25%;
      position: relative;
      margin-bottom: @gap;
      border-radius: @br;
      .box-shadow(@shadow);

      img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        font-family: 'object-fit: cover;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.prev-img__left{
      float: left;
      margin-right: @gap;
    }
    &.prev-img__right{
      float: right;
      margin-left: @gap;
    }
  }

  .quote,
  .quote_c,
  blockquote {
    position: relative;
    margin-bottom: @gap*2;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    background: @color_b6 + 5%;
    border-radius: @br/2;
    padding: 20px 20px 20px 60px;
    box-shadow: @shadow;
  }

  .quote_c {
    text-align: center;
    padding-left: 20px !important;
  }

  .quote,
  blockquote {
    &::before {
      content: "";
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      background: @color_b3;
      border-radius: @br/2 0 0 @br/2;
    }

    &::after {
      content: "\f10d";
      width: 40px;
      height: 40px;
      position: absolute;
      color: rgba(@color_b2, 1);
      .fas;
      .centerxy;
      font-size: 23px;
      top: 20px;
      left: 8px;
      z-index: 2;
      display: block;
    }
  }

  ul:not(.todo-list){
    display: inline-block;
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      min-height: 25px;
      padding-left: 18px;

      &:not(:last-of-type){
        margin-bottom: @gap;
      }

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 2px;
        top: 7px;
        z-index: 3;
        background: rgba(@color_2 , .7);
        border-radius: 100%;
      }

      > * {
        margin: 10px auto 5px;

        > li {

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 2px;
            top: 7px;
            z-index: 3;
            background: @color_b3;
            border-radius: 100%;
          }
        }
      }
    }
  }

  ul.todo-list{
    display: inline-block;
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 18px;

      label {
        position: absolute;
        left: 0;
        top: -2px;
        margin-right: 10px;
      }
    }
  }

  ol {
    display: inline-block;
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 30px;

      &::before {
        content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color_w;
        background: @color_2;
        width: 23px;
        height: 23px;
        .centerxy;
        position: absolute;
        line-height: 17px;
        left: 0;
        top: 3px;
        font-size: 12px;
        font-weight: @f_bold;
        border-radius: @br;
      }
    }
  }

  .action-color{
    color: @color_1;
  }


  .float-right{
    float: right;
    max-width: 33.33%;
    padding-left: @gap;
    margin-bottom: @gap;

    img{
      width: 100% !important;
      height: auto !important;
    }

    a, code, span{
      font-size: 13px;
      line-height: 118%;
    }
  }
  .float-left{
    float: left;
    max-width: 33.33%;
    padding-right: @gap;
    margin-bottom: @gap;

    img{
      width: 100% !important;
      height: auto !important;
    }

    a, code, span{
      font-size: 13px;
      line-height: 118%;
    }
  }


}





// LISTS STYLE
//=============================================================================
[class*='list_v'] {
  margin: 0 auto 20px;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.list_v1 {
  li {

    &:before {
      content: @fa-var-check;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 0;
      .fas;
      z-index: 3;
      .centerxy;
      font-size: 17px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_2;
    }
  }
}

.list_v2 {
  li {
    &:before {
      content: "\f00d";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 3;
      .centerxy;
      font-size: 16px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_4 !important;
      .fas;
    }
  }
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;

  > li {
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color_w;
      background: @color_1;
      width: 25px;
      height: 25px;
      .centerxy;
      position: absolute;
      line-height: 17px;
      left: -8px;
      top: 0;
      font-size: 13px;
      font-weight: 600;
      border-radius: @br;

    }
  }
}

.list_v4 {
  li {
    padding: 0 0 14px 0;
    border-bottom: 1px solid @color_b7;
  }
}

.list_v5 {
  > li {
    margin-bottom: 10px;

    &::before {
      content: "\f111";
      background-position: 50% -20px * 18;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 1px;
      font-size: 9px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_2;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: "\f111";
          background-position: 50% -20px * 18;
          width: 20px;
          height: 20px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}

.list_v6 {
  > li {
    margin-bottom: 8px;
    padding-left: 13px;

    &::before {
      content: @fa-var-circle;
      width: 10px;
      height: 10px;
      position: absolute;
      left: -3px;
      top: 3px;
      font-size: 6px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_1;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: @fa-var-circle;
          background-position: 50% -20px * 18;
          width: 10px;
          height: 10px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}


